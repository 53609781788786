<template>
    <div class="travelmap">
        <Header class="hide" />
    <Headers class="dispy" />
        <div class="bar">
            <BannerImage rulename="query_banner" />
            <div class="w">
                <Breadcrumb :crumblist="crumbdata" class="hide" />
                <div class="map-container clear">
                    <el-row>
                        <el-col :md="20">
                            <baidu-map
                                class="bm-view"
                                :center="center"
                                :zoom="zoom"
                                :scroll-wheel-zoom="true"
                                @ready="readyhandler"
                                @reload="reloadHandler"
                            >
                                <!-- 比例尺 -->
                                <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                                <!-- 全景 -->
                                <bm-panorama></bm-panorama>
                                <!-- 点 -->
                                <bml-marker-clusterer :averageCenter="true">
                                    <div v-for="(marker, i) of markers" :key="i">
                                        <bm-marker
                                            :position="{
                                                lng: marker.theIng,
                                                lat: marker.theLat,
                                            }"
                                            @click="infoWindowOpen(marker)"
                                            animation="BMAP_ANIMATION_BOUNCE"
                                            :icon="{
                                                url: i == indexing? '/image/icon/point-active.png' : '/image/icon/point.png',
                                                size: {width: 30, height: 40},
                                                opts: {imageSize: {width: 30, height: 40}}
                                            }"
                                        >
                                            <bm-info-window
                                                :position="{
                                                    lng: marker.theIng,
                                                    lat: marker.theLat,
                                                }"
                                                :closeOnClick="false"
                                                :show="marker.theShow"
                                                :width="350"
                                                :height="200"
                                                @close="infoWindowClose(marker)"
                                                @open="infoWindowOpen(marker)"
                                            >
                                                <div class="custom-windows">
                                                    <img :src="`/jeecg-boot/${marker.thePic}`" class="custom-image">
                                                    <div class="custom-info">
                                                        <h4>{{ marker.theName }}</h4>
                                                        <p><i class="el-icon-phone"></i> 电话: {{ marker.thePhone }}</p>
                                                        <p><i class="el-icon-location-information"></i> 地址: {{ marker.theAdd }}</p>
                                                    </div>
                                                </div>
                                            </bm-info-window>
                                        </bm-marker>
                                    </div>
                                </bml-marker-clusterer>
                            </baidu-map>
                        </el-col>
                        <el-col :md="4">
                            <ul class="trave-list">
                                <li class="trave-item" :class="index == indexing ? 'trave-active' : ''" v-for="(item,index) in markers" :key="item.id" @click="dadHandler(index)">
                                    <h4 class="title">{{ item.theName }}</h4>
                                    <p><i class="el-icon-phone"></i> 电话: {{ item.thePhone }}</p>
                                    <p><i class="el-icon-location-information"></i> 地址: {{ item.theAdd }}</p>
                                </li>
                            </ul>
                        </el-col>
                    </el-row>
                    
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import { getAction } from "@/api/manage";
import BannerImage from "@/components/main/BannerImage";
import { BmlMarkerClusterer } from "vue-baidu-map";

export default {
    components: {
        Header,
        Footer,
        Breadcrumb,
        BannerImage,
        Headers
    },
    created() {
        this.loadData();
    },
    data() {
        return {
            crumbdata: [
                { name: "信息查询", url: "" },
                { name: "旅游地图", url: "/travelmap" },
            ],
            center: { lng: 0, lat: 0 },
            zoom: 13,
            result: [],

            markers:[],

            indexing: 0
        };
    },
    methods: {
        loadData() {
            getAction('/lymap/tourMap/listNoPg').then(res => {
                if(res.success){
                    this.markers = res.result
                    if(res.result.length != 0){
                        this.reloadHandler(res.result[0].theIng,res.result[0].theLat)
                    }
                }
            })
        },
        readyhandler({ BMap, map }) {
            this.center.lng = 107.032033;
            this.center.lat = 35.311922;
        },
        infoWindowClose (marker) {
            marker.theShow = false
        },
        infoWindowOpen (marker) {
            marker.theShow = true
        },
        dadHandler(index){
            this.indexing  = index
            this.reloadHandler(107.032949,35.307769)
        },
        reloadHandler(lng,lat){
            this.center.lng = lng
            this.center.lat = lat
        }
    },
};
</script>
<style lang="scss" scoped>
.map-container {
    margin-bottom: 50px;
    border: 2px solid #6EB400;
    .bm-view {
        width: 100%;
        height: 600px;
    }
    .custom-windows{
        padding: 10px;
        .custom-image{
            width: 100%;
            height: 130px;
        }
        .custom-info{
            p{
                font-size: 12px;
                color: #333;
            }
        }
    }
    .trave-list{
        background-color: #f4f4f4;
        border-left: 2px solid #6EB400;
        height: 600px;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 10px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #9bc95c;
        }
        &::-webkit-scrollbar-track{
            background-color: #fff;
        }
        .trave-item{
            padding: 10px 0;
            padding-left: 20px;
            border-bottom: 1px dashed #999;
            cursor: pointer;
            .title{
                margin-bottom: 5px;
            }
            p{
                font-size: 12px;
            }
        }
        .trave-active{
            background-color: #6EB400;
            color: #fff;
        }
    }
}
</style>
