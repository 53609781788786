var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"travelmap"},[_c('Header',{staticClass:"hide"}),_c('Headers',{staticClass:"dispy"}),_c('div',{staticClass:"bar"},[_c('BannerImage',{attrs:{"rulename":"query_banner"}}),_c('div',{staticClass:"w"},[_c('Breadcrumb',{staticClass:"hide",attrs:{"crumblist":_vm.crumbdata}}),_c('div',{staticClass:"map-container clear"},[_c('el-row',[_c('el-col',{attrs:{"md":20}},[_c('baidu-map',{staticClass:"bm-view",attrs:{"center":_vm.center,"zoom":_vm.zoom,"scroll-wheel-zoom":true},on:{"ready":_vm.readyhandler,"reload":_vm.reloadHandler}},[_c('bm-scale',{attrs:{"anchor":"BMAP_ANCHOR_TOP_RIGHT"}}),_c('bm-panorama'),_c('bml-marker-clusterer',{attrs:{"averageCenter":true}},_vm._l((_vm.markers),function(marker,i){return _c('div',{key:i},[_c('bm-marker',{attrs:{"position":{
                                            lng: marker.theIng,
                                            lat: marker.theLat,
                                        },"animation":"BMAP_ANIMATION_BOUNCE","icon":{
                                            url: i == _vm.indexing? '/image/icon/point-active.png' : '/image/icon/point.png',
                                            size: {width: 30, height: 40},
                                            opts: {imageSize: {width: 30, height: 40}}
                                        }},on:{"click":function($event){return _vm.infoWindowOpen(marker)}}},[_c('bm-info-window',{attrs:{"position":{
                                                lng: marker.theIng,
                                                lat: marker.theLat,
                                            },"closeOnClick":false,"show":marker.theShow,"width":350,"height":200},on:{"close":function($event){return _vm.infoWindowClose(marker)},"open":function($event){return _vm.infoWindowOpen(marker)}}},[_c('div',{staticClass:"custom-windows"},[_c('img',{staticClass:"custom-image",attrs:{"src":("/jeecg-boot/" + (marker.thePic))}}),_c('div',{staticClass:"custom-info"},[_c('h4',[_vm._v(_vm._s(marker.theName))]),_c('p',[_c('i',{staticClass:"el-icon-phone"}),_vm._v(" 电话: "+_vm._s(marker.thePhone))]),_c('p',[_c('i',{staticClass:"el-icon-location-information"}),_vm._v(" 地址: "+_vm._s(marker.theAdd))])])])])],1)],1)}),0)],1)],1),_c('el-col',{attrs:{"md":4}},[_c('ul',{staticClass:"trave-list"},_vm._l((_vm.markers),function(item,index){return _c('li',{key:item.id,staticClass:"trave-item",class:index == _vm.indexing ? 'trave-active' : '',on:{"click":function($event){return _vm.dadHandler(index)}}},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(item.theName))]),_c('p',[_c('i',{staticClass:"el-icon-phone"}),_vm._v(" 电话: "+_vm._s(item.thePhone))]),_c('p',[_c('i',{staticClass:"el-icon-location-information"}),_vm._v(" 地址: "+_vm._s(item.theAdd))])])}),0)])],1)],1)],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }